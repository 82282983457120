<template>
<div>
  <div class="row" style="min-height: 100vh;">
    <div class="col-6">
      <div class="middle">
        <h1>COMING SOON</h1>
        <hr>
        <p>Website under construction</p>
      </div>
    </div>
    <div class="col-6" style="background-color: black">
      <div class="middle-logo">
        <img :src="require('../../assets/img/logo-invert-full.png')" alt="site logo"/>
      </div>
    </div>
  </div>
<!--  <div class="bgimg">-->
<!--    <div class="topleft">-->
<!--      <p>Logo</p>-->
<!--    </div>-->
<!--    <div class="middle">-->
<!--      <h1>COMING SOON</h1>-->
<!--      <hr>-->
<!--      <p>35 days</p>-->
<!--    </div>-->
<!--    <div class="bottomleft">-->
<!--      <p>Some text</p>-->
<!--    </div>-->
<!--  </div>-->
</div>
</template>

<script>
export default {
  name: "ConstructionPage",
  data() {
    return {
      breadcrumb: {
        page: "Error 404",
        pageTitle: "Error 404"
      }
    }
  }
}
</script>

<style scoped>

/* Position text in the middle */
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.middle-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  /*height: 90%;*/
}

/* Style the <hr> element */
hr {
  margin: auto;
  width: 40%;
}
</style>